.appContainer {
  display: flex;
  flex-direction: column;
  // min-height: 100vh;

  .routeContent {
    flex-grow: 1;
  }

  .whatsappContainer {
    position: fixed;
    bottom: 5%;
    right: 5%;
    width: 3rem;
    height: 3rem;
    background-color: white;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    z-index: 999;

    .whatsappLogo {
      width: 75%;
      height: 75%;
      border-radius: 50%;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    }
  }
}

body {
  // background-image: url("../assets/images/bg.avif");
  background-repeat: no-repeat;
  background-size: cover;
}