.productDetailContainer {
    margin: 3rem;
    display: flex;
    gap: 2rem;

    .productImage {
        max-width: 50%;
        max-height: 40rem;
        border-radius: 12px;
        overflow: hidden;
        position: relative;

        .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 12px;
        }
    }
}

.imgMagnifierGlass {
    position: absolute;
    border: 3px solid #000;
    border-radius: 50%;
    cursor: none;
    width: 100px;
    height: 100px;
    display: block;
    /* Make visible for debugging */
    z-index: 10;
    /* Ensure it's on top */
    pointer-events: none;
    /* Ensure it doesn't block image events */
}

.contactUsFormInProducts {
    width: 50%;
    max-height: 80%;
    display: flex;
    justify-content: center;
    position: relative;
    transform: translate(50%, 20%);
    background: white;
    border-radius: 1rem;
    padding: 1rem;
    background-image: url("../assets/images/bg6.jpeg");
}

@media (max-width: 480px) {
    .productDetailContainer {
        margin: 3rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .productImage {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 90%;
            max-height: 40rem;
            border-radius: 12px;
            overflow: hidden;
            position: relative;

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 12px;
            }
        }
    }

    .contactUsFormInProducts {
        width: 95%;
        max-height: 80%;
        display: flex;
        justify-content: center;
        position: relative;
        transform: translate(2.5%, 10%);
        background: white;
        border-radius: 1rem;
    }
}