.carouselContainer {

    .imageClass {
        width: 100%;
        height: 40rem;
        object-fit: cover;
    }

    .carouselCaption {
        position: relative;
        top: -4rem;
        left: -31rem;

        .carouselButtons {
            width: 20rem;
            height: 3rem;
            border-radius: 12px;
            color: white;
            background-color: black;
            font-size: 1.5rem;
            border: 1px solid black;
        }

        .carouselDescription {
            margin-top: 1rem;
            font-size: 1.2rem;
            font-weight: 600;
            color: black;
            opacity: 0.7;
            font-family: cursive;
        }
    }
}

@media (max-width: 1280px) {
    .carouselContainer {

        .carouselCaption {
            position: relative;
            top: -5rem;
            left: -25rem;
        }
    }
}

@media (max-width: 1024px) {
    .carouselContainer {

        .imageClass {
            width: 100%;
            height: 27rem;
            object-fit: cover;
        }

        .carouselCaption {
            position: relative;
            top: 0.5rem;
            left: -20rem;
        }
    }
}

@media (max-width: 768px) {
    .carouselContainer {

        .imageClass {
            width: 100%;
            height: 20rem;
            object-fit: cover;
        }

        .carouselCaption {
            position: relative;
            top: 1rem;
            left: -15rem;

            .carouselButtons {
                width: 12rem;
                height: 2.5rem;
                font-size: 1rem;
            }

            .carouselDescription {
                margin-top: 0.5rem;
                font-size: 0.75rem;
                font-weight: 500;
                opacity: 0.7;
            }
        }
    }
}

@media (max-width: 480px) {
    .carouselContainer {

        .imageClass {
            width: 100%;
            height: 12.5rem;
            object-fit: cover;
        }

        .carouselCaption {
            position: relative;
            top: 1.8rem;
            left: -7.2rem;

            .carouselButtons {
                width: 7.5rem;
                height: 1.5rem;
                font-size: 0.5rem;
            }

            .carouselDescription {
                margin-top: 0.5rem;
                font-size: 0.5rem;
                font-weight: 600;
                opacity: 0.7;
            }
        }
    }
}