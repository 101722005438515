.productContainer {
  display: flex;
  flex-direction: column;
  margin: 2rem 4rem;
  margin-top: 0;
  padding: 0rem 2rem;
  justify-content: center;

  .title {
    font-size: 2.5rem;
    font-weight: 400;
    font-family: Cinzel, serif;
    letter-spacing: 1.71pt;
    text-transform: uppercase;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }

  .productCard {
    position: relative;
    overflow: hidden;
    height: 22rem;
    display: flex;
    justify-content: center;

    .cardImage {
      height: 100%;
      width: 100%;
    }

    .cardOverlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1rem;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      text-align: center;
      height: 18%;

      .cardTitle {
        margin-bottom: 8px;
        font-size: 1.2rem;
      }

      .buttonContainer {
        display: flex;
        justify-content: center;

        button {
          opacity: 0.75;
          transition: opacity 0.3s ease-in-out;
          color: #fff;
          background-color: #007bff;
          border: none;
        }

        &:hover {
          button {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .productContainer {
    display: flex;
    flex-direction: column;
    margin: 0rem;
    padding: 0rem 2rem;

    .title {
      font-size: 2rem;
      letter-spacing: 1.5pt;
      padding-bottom: 0rem;
    }

    .productCard {
      height: 10rem;

      .cardImage {
        height: 100%;
        width: 100%;
      }

      .cardOverlay {
        padding: 0.5rem;
        height: 23%;

        .cardTitle {
          margin-bottom: 6px;
          font-size: 0.7rem;
        }

        .buttonContainer {

          button {
            padding: 0.25rem 0.75rem;
            font-size: 0.6rem;
          }

          &:hover {
            button {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}