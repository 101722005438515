@mixin titleHeading {
  font-size: 2.5rem;
  font-weight: 400;
  font-family: Cinzel, serif;
  letter-spacing: 1.71pt;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 0rem;
}

@mixin awardText {
  font-size: 1.5rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  font-family: 'Courier New', Courier, monospace;
}

.aboutusContainer {
  background-image: url("../assets/images/bg2.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem;
  border-radius: 0.5rem;

  .aboutusHeading {
    @include titleHeading();
    padding-bottom: 1rem;
  }

  .aboutusDetailContainer {
    display: flex;
    align-items: center;
    // background-color: #f5f5f5;
    padding: 1rem;

    .aboutusDetailImage {
      width: 30rem;
      height: 30rem;
      overflow: hidden;
      padding: 1rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .aboutusDetailText {
      flex: 1;

      p {
        font-size: 1.25rem;
        margin-bottom: 0.75rem;
        margin-left: 1rem;
        padding: 1rem;
        font-family: Arial, Helvetica, sans-serif;
      }
    }
  }

  .founderContainer {

    .founderHeading {
      @include titleHeading();
      padding-bottom: 1rem;
    }

    .imageTextB {
      @include titleHeading();
      margin-top: 0rem;
      font-size: 2rem;
      font-family: Cinzel, serif;
    }

    .imageTextC {
      @include titleHeading();
      margin-top: 0rem;
      font-size: 1.5rem;
      text-transform: none;
      font-family: Cinzel, serif;

      .managerName {
        margin: 0 8px;
      }
    }

    .founderImagesContainer {
      display: flex;
      justify-content: space-around;
      margin: 2rem 0rem;

      .founderImage {
        width: 40%;
        // height: 100%;
        object-fit: cover;

        .imageFounder {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .awardsContainer {
    width: 100%;

    .awardsHeading {
      @include titleHeading();
      padding-bottom: 1rem;
    }

    .awards {
      background-image: url('../assets/images/awards/awards-bg.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .awardsSection1 {
        display: flex;
        justify-content: center;
        margin-top: 3rem;

        .awardsText {
          @include awardText();
        }
      }

      .awardsSection2 {
        display: flex;
        justify-content: space-around;
        margin: 2rem 0rem;

        .awardsSection20 {
          display: flex;
          flex-direction: column;
          align-items: center;

          .awardsText {
            @include awardText();
            margin-bottom: 0px;
          }
        }
      }
    }

  }
}

@media screen and (max-width: 768px) {
  .aboutusContainer {

    .aboutusHeading {}

    .aboutusDetailContainer {
      flex-direction: column;
      padding: 0rem;


      .aboutusDetailImage {
        width: 20rem;
        height: 20rem;
      }

      .aboutusDetailText {
        p {
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .aboutusContainer {
    padding: 1rem;

    .aboutusHeading {
      font-size: 1.5rem;
      letter-spacing: 1.41pt;
      padding-bottom: 0rem;
    }

    .aboutusDetailContainer {
      flex-direction: column;
      padding: 0rem;


      .aboutusDetailImage {
        width: 20rem;
        height: 20rem;
      }

      .aboutusDetailText {
        p {
          font-size: 1rem;
        }
      }
    }

    .founderContainer {

      .founderHeading {
        font-size: 1.5rem;
        letter-spacing: 1.41pt;
        padding-bottom: 0rem;
      }

      .imageTextB {
        @include titleHeading();
        margin-top: 0rem;
        font-size: 0.9rem;
        font-family: Cinzel, serif;
      }

      .imageTextC {
        display: block;
        margin-top: 0.5rem;
        font-size: 0.7rem;
      }

      .founderImagesContainer {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem;

        .founderImage {
          width: 90%;
          object-fit: cover;

          .imageFounder {
            width: 100%;
            object-fit: cover;
          }
        }

      }
    }

    .awardsContainer {

      .awardsHeading {
        font-size: 1.4rem;
        letter-spacing: 1.21pt;
        padding-bottom: 1rem;
      }

      .awards {
        background-repeat: repeat;

        .awardsSection1 {
          display: flex;
          justify-content: center;
          margin-top: 3rem;

          .awardsText {
            font-size: 1rem;
            letter-spacing: -1pt;
          }
        }

        .awardsSection2 {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin: 2rem 0rem;

          .awardsSection20 {
            display: flex;
            flex-direction: column;
            align-items: center;

            .awardsText {
              font-size: 1rem;
              letter-spacing: -1pt;
              margin-bottom: 0px;
            }
          }
        }
      }

    }
  }
}