.button-solid {
    position: relative;
    width: 8rem;
    height: 2rem;
    padding: 4px 8px;
    margin: 8px;
    background-color: #691b1d;
    outline: none;
    border: 1px solid #691b1d;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

.button-outlined {
    position: relative;
    width: 8rem;
    height: 2rem;
    padding: 4px 8px;
    margin: 8px;
    color: #691b1d;
    background-color: white;
    outline: none;
    border: 1px solid #691b1d;
    border-radius: 4px;
    cursor: pointer;
}

.button:active {
    background: #583b01;
}

.button__text-solid {
    color: white;
    transition: all 0.2s;
}

.button__text-outlined {
    color: #691b1d;
    transition: all 0.2s;
}

.button--loading .button__text {
    visibility: hidden;
    opacity: 0;
}

.button--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0px;
    right: 16px;
    // right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}

.button-large {
    width: 12rem;
}

.button-small {
    width: 6rem;
    height: 1.5rem;
    padding: 4px 8px;
    margin: 8px;
    font-size: 10px;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}