@mixin headingStyle {
  font-size: 2.5rem;
  font-weight: 400;
  font-family: Cinzel, serif;
  letter-spacing: 1.71pt;
  text-transform: uppercase;
  margin-bottom: 0rem;
}

.patternImage {
  background-image: url("../assets/images/bg1.jpeg");
  background-repeat: no-repeat;
  background-size: cover;


  .sectionContainer {
    padding-bottom: 2rem;
  }

  .bestSellerCorouselComponent {

    .bestSellerHeading {
      @include headingStyle();
      display: flex;
      justify-content: center;
      padding-bottom: 2rem;
    }
  }

  .trustIsSafe {
    padding: 3rem;
    background-color: #3e3e3e;
    color: white;
    text-align: center;

    .trustIsSafeHeading {
      @include headingStyle();
    }

    .trustIsSafeSubHeading {
      font-size: 1.2rem;
      font-weight: 300;
      margin-bottom: 2rem;
    }

    .trustItems {
      display: flex;
      gap: 2rem;
      margin-top: 2rem;
      justify-content: center;
      flex-wrap: wrap;

      .trustItem {
        flex: 1;
        text-align: center;
        margin: 1rem;

        .trustItemSVG {
          width: 100%;
          height: auto;
          margin-bottom: 0.5rem;
          aspect-ratio: 5/2;
        }

        .trustItemImageText {
          margin-top: 0.5rem;
        }
      }
    }
  }

  .storeLocatorContainer {
    .locationHeading {
      font-size: 2.5rem;
      font-weight: 400;
      font-family: Cinzel, serif;
      margin: 0 auto;
      letter-spacing: 1.71pt;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      padding-top: 1rem;
    }

    .storeLocationInHome {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0;

      .storeLocator {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .patternImage {

    .sectionContainer {
      padding-bottom: 1rem;
    }

    .bestSellerCorouselComponent {

      .bestSellerHeading {
        @include headingStyle();
        display: flex;
        justify-content: center;
        font-size: 2rem;
        letter-spacing: 1.5pt;
        padding-bottom: 0.5rem;
      }
    }

    .trustIsSafe {
      padding: 1rem 0.5rem;

      .trustIsSafeHeading {
        font-size: 2rem;
        letter-spacing: 1.5pt;
      }

      .trustIsSafeSubHeading {
        font-size: 0.9rem;
        font-weight: 300;
      }

      .trustItems {
        gap: 0rem;
        margin-top: 1rem;
        flex-wrap: nowrap;

        .trustItem {
          margin: 0rem;

          .trustItemSVG {
            width: 6rem;
            height: 6rem;
            margin-bottom: 0.5rem;
            scale: 0.5;
          }

          .trustItemImageText {
            margin-top: 0.5rem;
            font-size: 12px;
          }
        }
      }
    }

    .storeLocatorContainer {
      .locationHeading {
        font-size: 2rem;
        letter-spacing: 1.5pt;
      }

      .storeLocationInHome {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0;

        .storeLocator {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}