.bestSellerContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    .imageWrapper {
        margin: 0 10px;
        flex: 1;
        cursor: pointer;
        position: relative;
        // overflow: hidden;
        z-index: 1;
        transition: z-index 0s, transform 0.3s ease-in-out;

        .imageClass {
            width: 100%;
            height: 16rem;
        }

        .imageName {
            display: none;
            position: absolute;
            bottom: 2rem;
            width: 100%;
            // color: antiquewhite;
            // color: beige;
            color: bisque;
            // color: chocolate;
            text-align: center;
            padding: 0.5rem 0;
            box-sizing: border-box;
            font-size: 24px;
            transition: opacity 0.3s ease-in-out;
        }

        &:hover .imageName {
            display: block;
            opacity: 1;
        }
    }

    .imageWrapper:hover .imageClass {
        transform: scale(1.2);
    }

    .imageWrapper:hover {
        z-index: 10;
    }
}

@media (max-width: 768px) {
    .bestSellerContainer {

        .imageWrapper {

            margin: 0 6px;

            .imageClass {
                width: 100%;
                height: 12rem;
            }

            .imageName {
                display: none;
                position: absolute;
                bottom: 1rem;
                font-size: 16px;
            }

            &:hover .imageName {
                display: block;
            }
        }

        .imageWrapper:hover .imageClass {
            transform: scale(1.1);
        }
    }
}

@media (max-width: 480px) {
    .bestSellerContainer {
        margin-bottom: 1rem;

        .imageWrapper {
            margin: 0 2px;

            .imageClass {
                width: 100%;
                height: 8rem;
            }

            .imageName {
                display: none;
                position: absolute;
                bottom: 1rem;
                font-size: 12px;
            }

            &:hover .imageName {
                display: block;
            }
        }

        .imageWrapper:hover .imageClass {
            transform: scale(1.1);
        }
    }
}