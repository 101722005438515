.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    background-image: url("../assets/images/bg6.jpeg");
    padding: 1rem 0;
    border-radius: 1rem;

    .formClose {
        position: absolute;
        right: 2rem;
        top: 1rem;
    }

    .formRow {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        .inputLabel {
            font-size: 1.2rem;
            font-weight: 400;
            margin-bottom: 0.5rem;
        }

        .required {
            color: red;
        }

        .inputText {
            width: 20rem;
            height: 2rem;
            margin-top: 0.5rem;
            border: 1.5px solid black;
            border-radius: 8px;
            font-size: 1rem;
            padding: 4px 8px;
            outline: none;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }

        .customTextarea {
            resize: none;
            width: 90%;
            height: 100px;
            outline: none;
            margin-top: 8px;
            font-size: 16px;
        }

        .containerSelect {
            max-width: 20.5rem;
            height: 2rem;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            display: flex;
            color: black;
            padding: 4px;
            border-radius: 8px;
            border: 1px solid black;
        }

        .selectTag {
            height: 2.5rem;
            width: 20rem;
            border: 1px solid black;
            outline: none;
        }

        .errorText {
            font-size: 16px;
            font-weight: 400;
            color: red;
        }
    }

    .bookAppointmentFormButton {
        display: flex;
        justify-content: center;
    }
}