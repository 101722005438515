.footer {
  background-color: #f5f5f5;
  padding: 20px;

  .footerContainer {
    display: flex;
    justify-content: space-around;
    gap: 1rem;

    .footerSection {
      flex: 2;

      .footerImage {
        width: 10rem;
        height: 9rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        scale: 1.5;
        margin-left: 3rem;

        .imageFooter {
          margin: 0;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .footerConnectSection {
      flex: 1;

      .footerConnectHeading {
        font-size: 2rem;
        color: #691b1d;
      }

      ul {
        list-style: none;
        padding: 1rem;

        li {
          margin-bottom: 0.5rem;

          a {
            text-decoration: none;
            color: #691b1d;
            font-size: 1.3rem;
            padding: 1rem 0.5rem;
          }
        }
      }
    }

    .footerLinkSection {
      flex: 1;

      .footerLinkHeading {
        font-size: 2rem;
        color: #691b1d;
      }

      ul {
        list-style: none;
        padding: 1rem;

        li {
          margin-bottom: 0.5rem;

          a {
            text-decoration: none;
            color: #691b1d;
            font-size: 1.3rem;
            padding: 1rem 0.5rem;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .footer {
    .footerContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 0.5rem;

      .footerSection {

        .footerImage {
          width: 12rem;
          height: 10rem;
          margin-left: 4rem;
        }
      }

      .footerConnectSection {

        .footerConnectHeading {
          font-size: 1.2rem;
        }

        ul {
          padding: 0.1rem 0.5rem;

          li {
            margin-bottom: 0.2rem;

            a {
              font-size: 1rem;
              padding: 0.5rem 0.2rem;
            }
          }
        }
      }

      .footerLinkSection {

        .footerLinkHeading {
          font-size: 1.2rem;
        }

        ul {
          padding: 0.1rem 0.5rem;

          li {
            margin-bottom: 0.2rem;

            a {
              font-size: 1rem;
              padding: 0.5rem 0.2rem;
            }
          }
        }
      }
    }
  }
}