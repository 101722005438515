body {
  margin: 0;
  padding: 0;
  font-family: "Cinzel", sans-serif;

  ::-webkit-scrollbar {
    width: 0px;
  }

  // ::-webkit-scrollbar-track {
  //   background-color: white;
  // }

  // ::-webkit-scrollbar-thumb {
  //   background-color: rgb(192, 192, 192);
  //   border-radius: 12px;
  // }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(192, 192, 192);
  border-radius: 12px;
}