.bookAppointmentContainer {
    background-image: url("../assets/images/bg.avif");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;

    .bookAppointmentLeftContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 1rem;
        flex: 1;
        padding: 1rem;

        .appointmentHeading {
            font-size: 2.5rem;
            font-weight: 400;
            font-family: Cinzel, serif;
            margin: 0 auto;
            letter-spacing: 1.71pt;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            padding-top: 1rem;
        }

        .appointmentSubHeading {
            font-size: 1rem;
            font-weight: 400;
            margin: 0 auto;
            letter-spacing: 1pt;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 1rem;
        }

    }

    .bookAppointmentRightContainer {
        flex: 1;

        max-width: 100%;
        max-height: 45rem;

        img {
            width: 100%;
            height: 45rem;
        }
    }
}


@media (max-width: 480px) {
    .bookAppointmentContainer {
        display: flex;
        flex-direction: column-reverse;

        .bookAppointmentLeftContainer {

            .appointmentHeading {
                font-size: 1.4rem;
                letter-spacing: 1.2pt;
            }

            .appointmentSubHeading {
                font-size: 12px;
            }
        }

        .bookAppointmentRightContainer {
            max-width: 100%;
            max-height: 30rem;

            img {
                width: 100%;
                height: 30rem;
            }
        }
    }
}