.productContainer {
    margin: 1rem;
    display: flex;
    max-height: 600px;

    .productFilterContainer {
        background-image: url("../assets/images/bg2.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        // width: 20%;
        flex: 2;
        padding: 1rem;
        border: 1px solid #e5e5e5;
        border-radius: 12px;

        .filter {
            display: flex;
            flex-direction: column;

            .filterHeading {
                margin-top: 1rem;
                margin-bottom: 0.2rem;
                padding: 1rem;
                color: #691b1d;
            }

            .filterItem {
                padding: 0.2rem 0.5rem;

                .filterItemCheckBox {
                    width: 12px;
                    height: 12px;
                    background-color: #691b1d;
                    cursor: pointer;
                    accent-color: #691b1d;
                }

                .filterItemName {
                    margin-left: 8px;
                }
            }
        }
    }

    .productDisplayContainer {
        background-image: url("../assets/images/bg3.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        flex: 8;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        overflow-y: auto;

        .productItem {
            width: 16rem;
            height: 18rem;
            position: relative;
            margin: 1rem;

            .productImage {
                width: 16rem;
                height: 16rem;
                border-radius: 8px;
            }

            .videoButton {
                position: absolute;
                left: 83%;
                bottom: 85%;
                cursor: pointer;
            }

            .productName {
                position: absolute;
                bottom: 48px;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.7);
                color: white;
                padding: 5px;
                text-align: center;
                opacity: 0;
                transition: opacity 0.3s;
            }

            .enquiryButton {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .productItem:hover .productName {
            opacity: 1;
        }
    }
}

.contactUsFormInProducts {
    width: 50%;
    max-height: 80%;
    display: flex;
    justify-content: center;
    position: relative;
    transform: translate(50%, 20%);
    background: white;
    border-radius: 1rem;
    padding: 1rem;
    background-image: url("../assets/images/bg6.jpeg");
}

.videoAppointmentInProducts {
    width: 50%;
    max-height: 80%;
    display: flex;
    justify-content: center;
    position: relative;
    transform: translate(50%, 15%);
    background: white;
    border-radius: 1rem;
    padding: 1rem;
}

@media (max-width: 480px) {
    .productContainer {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        max-height: 800px;
        overflow-y: auto;
        // min-height: 60rem;

        .filterMobileButton {
            margin-left: 1rem;
            color: #691b1d;
            cursor: pointer;
            border: 1px solid #691b1d;
            border-radius: 12px;
            padding: 4px 8px;
            width: fit-content;
        }

        .productFilterContainer {
            border: 1px solid #e5e5e5;
            border-radius: 12px;
            flex: 1;

            .filter {
                display: flex;
                flex-direction: column;

                .filterHeading {
                    margin-top: 0.25rem;
                    margin-bottom: 0.2rem;
                    padding: 0.1rem;
                }

                .filterItem {
                    padding: 0;
                    height: 1.25rem;

                    .filterItemCheckBox {
                        width: 10px;
                        height: 10px;
                    }

                    .filterItemName {
                        margin-left: 8px;
                        font-size: 13px;
                    }
                }
            }
        }

        .productDisplayContainer {
            flex: 1;
            min-height: 30rem;

            .productItem {
                width: 40%;
                height: calc(100vw / 2.5);

                .productImage {
                    width: 100%;
                    height: calc(100vw / 2.73);
                    border-radius: 8px;
                }

                .videoButton {
                    position: absolute;
                    left: 80%;
                    bottom: 80%;
                    cursor: pointer;
                }

                .productName {
                    font-size: 12px;
                }
            }

            .productItem:hover .productName {
                opacity: 1;
                font-size: 12px;
            }
        }
    }

    .contactUsFormInProducts {
        width: 95%;
        max-height: 80%;
        display: flex;
        justify-content: center;
        position: relative;
        transform: translate(2.5%, 10%);
        background: white;
        border-radius: 1rem;
    }
}