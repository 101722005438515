.header {
  background-color: #ffffff;
  padding: 1rem 3rem;
  padding-right: 2rem;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;


  .logoContainer {
    height: 5rem;
    width: 6rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    scale: 1.5;

    .logoImage {
      margin: 0;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .navContainer {
    display: flex;
    width: fit-content;
    justify-content: space-around;
    align-items: center;

    .navComponent {
      text-decoration: none;
      color: #691b1d;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      font-size: 1.2rem;
      font-weight: 500;
      cursor: pointer;

      // &:hover {
      //   color: white;
      //   background-color: #691b1d;
      // }

      &.selected {
        color: white;
        background-color: #691b1d;
      }
    }
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

@media (max-width: 1280px) {
  .header {

    .navContainer {

      .navComponent {
        padding: 0.5rem 0.8rem;
        border-radius: 0.9rem;
        font-size: 1rem;
        font-weight: 500;

        &.selected {
          color: white;
          background-color: #691b1d;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .header {

    .navContainer {

      .navComponent {
        padding: 0.5rem 0.55rem;
        border-radius: 0.8rem;
        font-size: 0.7rem;
        font-weight: 400;

        &.selected {
          color: white;
          background-color: #691b1d;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header {

    .navContainer {

      .navComponent {
        padding: 0.5rem 0.5rem;
        border-radius: 0.7rem;
        font-size: 0.5rem;
        font-weight: 400;

        &.selected {
          color: white;
          background-color: #691b1d;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .header {
    padding: 1rem 4rem;
    padding-right: 2rem;
    position: relative;

    .menuIconContainer {
      display: block;
    }

    .navContainer {
      display: none;
    }

    .navContainerInMobile {
      width: 100%;
      height: 100vh;
      background: #835254;
      position: fixed;
      top: 0;
      left: -100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      z-index: 999;
      transform: translateX(100%);
      transition: all 1.0s ease-in;
      overflow-y: hidden;


      .menuCrossIconContainer {
        position: absolute;
        top: 5%;
        left: 80%;
        border: 1px solid #691b1d;
        border-radius: 50%;
        padding: 2px;
      }

      .navComponentMobile {
        text-decoration: none;
        color: #691b1d;
        padding: 0.5rem 1rem;
        border-radius: 1rem;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;

        // &:hover {
        //   color: white;
        //   background-color: #691b1d;
        // }

        &.selected {
          color: white;
          background-color: #691b1d;
        }
      }
    }
  }
}