.savingSchemeContainer {
    background-image: url("../assets/images/bg.avif");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    width: 100%;
    height: 50rem;

    .savingLeftSide {
        flex: 11;
        margin: 1rem;
        overflow: auto;

        .savingSchemeDetail {
            font-size: 16px;
            font-weight: 400;

            .savingSchemeDetailTerms {
                color: maroon;
            }

            .schemeDetail {
                color: maroon;
                font-weight: 500;
            }
        }

        .savingSchemeTable {
            display: flex;
            justify-content: center;

            table {
                border-collapse: collapse;
                width: 95%;

                td {
                    border: 3px solid maroon;
                    padding: 8px;
                    text-align: center;
                    font-weight: 700;
                    color: maroon;
                    width: calc(95% / 5);
                    height: 50px;
                }

                th {
                    padding: 8px;
                    text-align: center;
                    font-weight: 700;
                    color: white;
                    background-color: maroon;
                    width: calc(95% / 5);
                    height: 50px;
                }

                th:first-child {
                    border-left: 3px solid maroon;
                }

                th:last-child {
                    border-right: 3px solid maroon;
                }

                th:not(:first-child):not(:last-child) {
                    border-left: 3px solid white;
                    border-right: 3px solid white;
                }
            }
        }

        .SSFromHeading {
            font-size: 2.5rem;
            font-weight: 400;
            font-family: Cinzel, serif;
            margin: 0 auto;
            letter-spacing: 1.71pt;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            padding-top: 1rem;
        }

        .contactUsFormSS {
            margin: 1rem;
            display: flex;
            justify-content: center;
            // background-color: rgb(231, 231, 231);
            background-image: url("../assets/images/bg6.jpeg");
            border-radius: 1rem;
        }
    }

    .savingRightSide {
        flex: 9;
        max-width: 100%;
        max-height: 100%;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

@media (max-width: 480px) {
    .savingSchemeContainer {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        height: 100rem;

        .savingLeftSide {
            margin: 0.5rem;

            .savingSchemeDetail {
                font-size: 16px;
                font-weight: 400;

                .savingSchemeDetailTerms {
                    color: maroon;
                }

                .schemeDetail {
                    color: maroon;
                    font-weight: 400;
                }
            }

            .savingSchemeTable {
                display: flex;
                justify-content: center;

                table {
                    border-collapse: collapse;
                    width: 90%;

                    td {
                        border: 1px solid maroon;
                        padding: 4px;
                        width: calc(90% / 5);
                        font-weight: 500;
                    }

                    th {
                        padding: 4px;
                        text-align: center;
                        width: calc(90% / 5);
                        font-weight: 500;
                    }

                    th:first-child {
                        border-left: 2px solid maroon;
                    }

                    th:last-child {
                        border-right: 2px solid maroon;
                    }

                    th:not(:first-child):not(:last-child) {
                        border-left: 2px solid white;
                        border-right: 2px solid white;
                    }
                }
            }

            .SSFromHeading {
                font-size: 1.5rem;
                letter-spacing: 1.41pt;
            }

            .contactUsFormSS {
                margin: 4px;
            }
        }

        .savingRightSide {
            max-width: 100%;
            max-height: 30rem;

            img {
                width: 100%;
                height: 30rem;
            }
        }
    }
}