.contactusContainer {
    background-image: url("../assets/images/bg1.jpeg");
    background-repeat: no-repeat;
    background-size: cover;

    .locationHeading {
        font-size: 2.5rem;
        font-weight: 400;
        font-family: Cinzel, serif;
        margin: 0 auto;
        letter-spacing: 1.71pt;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        padding-top: 1rem;
    }

    .storeLocationInConatctUs {
        width: 100%;
        height: 35rem;
        display: flex;
        justify-content: center;
        margin: 0;

        .storeLocator {
            width: 100%;
            height: 100%;
        }

        .storeLocatorImage {
            width: 100%;
            height: 100%;
        }
    }

    .formContainer {
        width: 95%;
        margin-bottom: 1rem;
        // background-color: rgb(233, 231, 231);
        position: relative;
        left: 2.5%;

        .connectWithUsHeading {
            display: flex;
            justify-content: center;
            font-family: Cinzel, serif;
            margin: 0 auto;
            letter-spacing: 1.71pt;
            text-transform: uppercase;
            padding-top: 1rem;
            font-size: 2.5rem;
        }

        .contactusForm {
            display: flex;
            // justify-content: space-between;
            justify-content: center;
            width: 90%;
            align-items: center;
            // background-color: rgb(233, 231, 231);
            gap: 2rem;
            height: 35rem;

            .imageConatiner {
                width: 40rem;
                height: 30rem;
                overflow: hidden;
                padding: 1rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .getInTouchContainer {
        width: 100%;
        // margin-bottom: 1rem;
        background-color: rgb(233, 231, 231);
        // position: relative;
        // left: 2.5%;

        .getInTouchContainerHeading {
            display: flex;
            justify-content: center;
            font-family: Cinzel, serif;
            margin: 0 auto;
            letter-spacing: 1.71pt;
            text-transform: uppercase;
            padding-top: 1rem;
            font-size: 2.5rem;
        }

        .getInTouchComponents {
            display: flex;
            justify-content: space-around;
            gap: 12px;

            .getInTouchElements {
                width: 15rem;
                height: 12rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: center;

                .svgContainer {
                    width: 48px;
                    height: 48px;
                }

                .addressComponent {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    p {
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .contactusContainer {

        .locationHeading {
            font-size: 1.5rem;
            letter-spacing: 1.41pt;
        }

        .storeLocationInConatctUs {
            height: 25rem;
        }

        .formContainer {

            .connectWithUsHeading {
                font-size: 1.5rem;
                letter-spacing: 1.41pt;
            }

            .contactusForm {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                height: 50rem;

                .imageConatiner {
                    width: 100%;
                    height: 15rem;
                    overflow: hidden;
                    padding: 1rem;

                    img {
                        width: 100%;
                        height: 15rem;
                        object-fit: cover;
                    }
                }
            }
        }

        .getInTouchContainer {

            .getInTouchContainerHeading {
                font-size: 1.5rem;
                letter-spacing: 1.41pt;
            }

            .getInTouchComponents {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .getInTouchElements {
                    width: 15rem;
                    height: 12rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    align-items: center;
                    justify-content: center;

                    .svgContainer {
                        width: 48px;
                        height: 48px;
                    }

                    .addressComponent {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        p {
                            margin-bottom: 4px;
                        }
                    }
                }
            }
        }
    }
}